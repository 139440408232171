var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索条件" },
                    model: {
                      value: _vm.searchInfo.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "categoryId", $$v)
                      },
                      expression: "searchInfo.categoryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "订购ID", prop: "uniqueId", width: "80px" }
          }),
          _c("el-table-column", {
            attrs: { label: "标题", prop: "title", width: "130px" }
          }),
          _c("el-table-column", { attrs: { label: "描述", prop: "desc" } }),
          _c("el-table-column", {
            attrs: { label: "有效期", prop: "expire", width: "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.expire) + " 天 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "价格", prop: "price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" 原价 ： "),
                    scope.row.price == 0
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("免费")
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(
                            _vm._s(scope.row.price / 100) +
                              "元" +
                              _vm._s(scope.row.priceUnit)
                          )
                        ]),
                    _c("br"),
                    _vm._v(" 折后价： "),
                    scope.row.discountPrice == 0
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("免费")
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(
                            _vm._s(scope.row.discountPrice / 100) +
                              "元" +
                              _vm._s(scope.row.priceUnit)
                          )
                        ]),
                    _c("br")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "分类", prop: "categoryId" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getCategoryName(scope.row.categoryId)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "shortTitle" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("上架")
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("下架")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateSubscriptionServer(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑订购" : "创建订购"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "desc", $$v)
                      },
                      expression: "formData.desc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有效期:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.expire,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "expire", _vm._n($$v))
                      },
                      expression: "formData.expire"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "价格:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.price,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "price", _vm._n($$v))
                      },
                      expression: "formData.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.priceUnit,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "priceUnit", $$v)
                      },
                      expression: "formData.priceUnit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分类" },
                      model: {
                        value: _vm.formData.categoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "categoryId", $$v)
                        },
                        expression: "formData.categoryId"
                      }
                    },
                    _vm._l(_vm.categories, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠价:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.discountPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "discountPrice", _vm._n($$v))
                      },
                      expression: "formData.discountPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.shortTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "shortTitle", $$v)
                      },
                      expression: "formData.shortTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分类" },
                      model: {
                        value: _vm.formData.status,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "上架", value: 1 } }),
                      _c("el-option", { attrs: { label: "下架", value: 0 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }